import { GoogleAnalyticsConfig } from '@swivel-finance/consent';

declare var process: any;

export const ENV: {
    analytics: GoogleAnalyticsConfig;
} = {
    analytics: {
        enabled: process.env.ANALYTICS_ENABLED ?? false,
        measurementId: process.env.ANALYTICS_MEASUREMENT_ID ?? '',
    },
};

export default ENV;
