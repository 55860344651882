import { ConsentBanner, ConsentCategory, ConsentElement, ConsentGroup, ConsentService, ConsentStatus, ConsentTopics, CONSENT_TOPICS, GoogleAnalyticsConsentConfig, GoogleAnalyticsService, StorageConsentConfig } from '@swivel-finance/consent';
import '@swivel-finance/consent/dist/consent/components/consent-banner';
import '@swivel-finance/consent/dist/consent/components/consent-element';
import ENV from './environment';

let consentBanner: ConsentBanner<ConsentTopics> | undefined;
let consentElement: ConsentElement<ConsentTopics> | undefined;

const COOKIE_POLICY_URL = '/cookie-policy/';
const PRIVACY_POLICY_URL = '/privacy-policy/';

const groups: ConsentGroup<ConsentTopics>[] = [
    {
        category: ConsentCategory.PREFERENCES,
        description: 'Allow us to store your preferences on your device to improve your experience. Stored preferences include your cookie consent choices.',
        configs: [
            StorageConsentConfig,
        ],
    },
    {
        category: ConsentCategory.STATISTICS,
        description: 'Allow us to collect statistics data to improve our service. The collected information helps us understand how visitors interact with our service, and whether there may be technical issues.',
        configs: [
            GoogleAnalyticsConsentConfig,
        ],
    },
];

const consent = new ConsentService(CONSENT_TOPICS, groups);

const analytics = new GoogleAnalyticsService(consent, ENV.analytics);

const showConsentBanner = () => {

    consentBanner = document.createElement('sw-consent-banner') as ConsentBanner<ConsentTopics>;

    consentBanner.consentService = consent;
    consentBanner.cookiePolicyUrl = COOKIE_POLICY_URL;

    document.body.append(consentBanner);

    consentBanner.addEventListener('consent-settings', () => showConsentElement(), { once: true });
};

const showConsentElement = () => {

    if (consentElement) return;

    // if there's a consent banner on the page, dismiss it when opening the consent manager
    if (consentBanner) consentBanner.dismiss();

    consentElement = document.createElement('sw-consent') as ConsentElement<ConsentTopics>;

    consentElement.consentService = consent;
    consentElement.cookiePolicyUrl = COOKIE_POLICY_URL;
    consentElement.privacyPolicyUrl = PRIVACY_POLICY_URL;

    document.body.append(consentElement);

    // hide the consent element when any choice was made
    consentElement.addEventListener('consent-changed', () => {

        document.body.removeChild(consentElement!);
        consentElement = undefined;

    }, { once: true });
};

const checkConsent = () => {

    const consentProcessed = consent.read('storage') !== ConsentStatus.UNSET;
    const consentLink = document.getElementById('manage-consent') as HTMLAnchorElement | null;

    // if no consent has been processed/stored, show the consent element
    if (!consentProcessed) {

        showConsentBanner();
    }

    // if there's a consent link on the page, connect it to the consent element
    if (consentLink) {

        consentLink.addEventListener('click', event => {

            event.preventDefault();

            showConsentElement();
        });
    }
};

window.addEventListener('load', checkConsent);

export { consent, analytics };
