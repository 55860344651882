export class AnimationElement extends HTMLElement {

    protected src = '';

    connectedCallback () {

        this.src = this.getAttribute('src') || this.src;

        this.load();
    }

    protected async load () {

        if (!this.src) return;

        const response = await fetch(this.src);
        const content = await response.text();

        if (!content) return;

        const scripts = [] as string[];
        const parser = new DOMParser();

        const svg = parser.parseFromString(content, 'image/svg+xml');
        const fragment = document.createDocumentFragment();

        fragment.append(...Array.from(svg.childNodes));

        // dynamically appended scripts are not executed, we need to extract them
        fragment.querySelectorAll('script').forEach(script => {

            scripts.push(this.sanitizeScript(script.innerHTML));

            script.parentNode?.removeChild(script);
        });

        this.append(fragment);

        // we need to manually create script nodes for the extracted scripts to run them
        scripts.forEach(text => {

            const script = document.createElement('script');
            script.textContent = text;
            document.body.append(script);
        });
    }

    protected sanitizeScript (content: string): string {

        // svg is xml, so script data is often wrapped in CDATA sections
        // we don't want those when embedding svg directly in html
        return content.replace(/^<!\[CDATA\[/, '').replace(/]]>$/, '');
    }
}

customElements.define('sw-animation', AnimationElement);
