import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';

const template = function (this: CarouselElement) {

    return html`
    <div class="carousel-window" part="carousel-window">
        <slot class="carousel-pane" part="carousel-pane"></slot>
    </div>
    <nav class="carousel-nav" part="carousel-nav">
        ${ this.items.map((item, index) =>
            html`<a href="#"
                @click=${ (e: Event) => this.show(index, e) }
                class="carousel-link"
                part="carousel-link ${ this.current === index ? 'carousel-link-active' : '' }"
                aria-label="${ `page ${ index + 1 }` }"
                aria-current="${ this.current === index ? 'step' : 'false' }"></a>`) }
    </nav>
    `;
};

const styles = css`
*, *::before, *::after {
  margin: 0;
  padding: 0;
  min-width: 0;
  min-height: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-align: inherit;
  vertical-align: inherit;
  white-space: inherit;
  box-sizing: inherit;
}
:host {
    display: flex;
    flex-direction: column;
}
.carousel-window {
    display: flex;
    overflow: hidden;
}
.carousel-pane {
    display: flex;
    flex-flow: row nowrap;
    gap: var(--line-heigth, 1.5rem);
}
::slotted(*) {
    flex: 0 0 100%;
    width: 100%;
}
.carousel-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--line-heigth, 1.5rem);
    padding: var(--line-heigth, 1.5rem);
}
.carousel-link {
    display: block;
    flex: 0 0 auto;
    width: var(--line-height, 1.5rem);
    height: var(--line-height, 1.5rem);
    background-color: var(--theme-fg-dsbl, rgba(0, 0, 0, .1));
    border-radius: 50%;
}
.carousel-link[aria-current=step] {
    background-color: var(--theme-active);
}
`;

@customElement('sw-carousel')
export class CarouselElement extends LitElement {

    static styles = styles;

    protected resizeTimeout?: number;

    protected window!: HTMLElement;

    protected pane!: HTMLElement;

    protected items: HTMLElement[] = [];

    protected count = 0;

    @state()
    protected current = 0;

    constructor () {

        super();

        this.handleResize = this.handleResize.bind(this);
    }

    connectedCallback () {

        super.connectedCallback();

        this.items = Array.from(this.children) as HTMLElement[];
        this.count = this.items.length;

        window.addEventListener('resize', this.handleResize)
    }

    disconnectedCallback() {

        window.removeEventListener('resize', this.handleResize);
    }

    firstUpdated () {

        this.window = this.renderRoot.querySelector('.carousel-window')!;
        this.pane = this.renderRoot.querySelector('.carousel-pane')!;
    }

    updated () {

        this.items.forEach((item, index) => {

            if (this.current === index) {

                item.removeAttribute('aria-hidden');

            } else {

                item.setAttribute('aria-hidden', 'true');
            }
        });
    }

    render () {

        return template.apply(this);
    }

    show (index: number, event?: Event) {

        event?.preventDefault();
        event?.stopPropagation();

        this.current = index;

        this.window.scrollTo({
            behavior: 'smooth',
            left: this.items[this.current].offsetLeft - this.window.offsetLeft,
        });
    }

    /**
     * Corrects the scroll offset when the window is resized so the current page is always properly visible
     */
    protected handleResize () {

        if (!this.resizeTimeout) {

            this.resizeTimeout = requestAnimationFrame(() => {

                this.window.scrollLeft = this.items[this.current].offsetLeft - this.window.offsetLeft;

                this.resizeTimeout = undefined;

            });
        }
    }
}
